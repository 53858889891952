<template>
  <div>
    <div>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        @finish="handleFinish"
        :colon="false"
      >
        <a-form-item label="Name" :name="['group', 'name']">
          <a-input
            v-model:value="formState.group.name"
            placeholder="Enter Service Charge Name"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="Type" :name="['group', 'applyType']">
          <a-radio-group v-model:value="formState.group.applyType">
            <a-radio-button value="basic">Basic</a-radio-button>
            <a-radio-button value="inOrder">In-Order</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="Service Charges" :name="['group', 'values']">
          <a-select
            placeholder="Select Service Charges"
            mode="multiple"
            allow-clear
            v-model:value="selectedCharges"
            :options="serviceChargeOptions"
            @change="handleValuesSelect"
          />
        </a-form-item>
        <div>
          <a-button class="btn btn-primary px-5 mr-3" html-type="submit" :loading="isLoading">
            <span v-if="isEdit">Update</span>
            <span v-else>Add</span>
          </a-button>
        </div>
      </a-form>
    </div>
    <div>
      <a-divider>Test Data</a-divider>
      <div class="bg-zoho-light p-3 w-100">
        <div>
          <a-form-item label="Enter Test Value" name="testValue">
            <a-input-number
              v-model:value="testValue"
              placeholder="Test Value"
              :min="0"
              allow-clear
            />
          </a-form-item>
        </div>
        <div>
          <a-row>
            <a-col :md="24">
              <a-row>
                <a-col :md="12">
                  <p>Test Amount</p>
                </a-col>
                <a-col :md="12">
                  <p>{{ testValue || 'NA' }}</p>
                </a-col>
              </a-row>
              <div id="appliedServiceCharges" v-if="appliedCharges?.length > 0">
                <a-row v-for="(ob, idx) in appliedCharges" :key="idx">
                  <a-col :md="12">
                    <p>{{ ob.name }}</p>
                  </a-col>
                  <a-col :md="12">
                    <p>{{ ob.value }}</p>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isAliasEqual } from '@/services/helpers'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { INameValuePair } from '@/types/interfaces/INameValuePair'
import { AddonFormData } from '@/types/appcontracts/AddonFormData'
import { computed, defineComponent, PropType, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../../store/actions.type'
import { AddonFormType } from '@/types/enums/AddonFormType'
import { AddonApplyType } from '@/types/enums/AddonApplyType'
import { IAddonGroupType } from '@/types/interfaces/IAddonGroupType'
import * as utils from '@/services/helpers'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 14 },
}

const getInitialState = (prev: AddonFormData | null = null) => {
  const initialState: AddonFormData = {
    type: AddonFormType.Group,
    single: null,
    group: {
      name: prev?.group?.name || null,
      applyType: prev?.group?.applyType || AddonApplyType.Basic,
      values: prev?.group?.values || [],
    },
  }
  return initialState
}

export default defineComponent({
  name: 'ServiceChargeGroupInputForm',
  props: {
    editData: {
      type: Object as PropType<AddonFormData | null>,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const formRef = ref()
    const isEdit = computed(() => props.editData !== null)
    const formState = reactive(getInitialState(props.editData))
    const isLoading = ref<boolean>(false)
    const appliedCharges = ref<INameValuePair<number>[]>([])
    const testValue = ref<number | null>(null)
    const serviceChargeGroupList = computed(() => {
      return store.state.appGlobals.appConfigSettings?.serviceCharges?.groups as IAddonGroupType[]
    })
    const serviceChargeOptions = computed(() => {
      const singles = store.state.appGlobals.appConfigSettings?.serviceCharges
        ?.singles as INameValuePair<number>[]
      return (
        singles?.map((x) => {
          return {
            value: x.name,
            label: x.name,
            key: x.name,
            charge: x,
          }
        }) || []
      )
    })
    const selectedCharges = ref(props.editData?.group?.values?.map((x) => x.name) || [])
    const isNameUnique = async () => {
      return serviceChargeGroupList.value?.some((x) =>
        isAliasEqual(x.name, formState.group?.name || null),
      ) && !isAliasEqual(props.editData?.group?.name || null, formState.group?.name || null)
        ? Promise.reject('Name already exists.')
        : Promise.resolve(true)
    }

    const isValueValid = async () => {
      return formState.group?.values && formState.group?.values?.length > 0
        ? Promise.resolve(true)
        : Promise.reject('Selection list cannot be empty')
    }

    const rules = reactive({
      group: {
        name: [
          {
            required: true,
            message: 'Please enter Service Charge Name',
            trigger: 'blur',
            type: 'string',
          },
          { validator: isNameUnique, trigger: 'blur' },
        ],
        values: [{ validator: isValueValid, trigger: 'blur' }],
      },
    })

    const handleValuesSelect = (_: any, ops: Array<any>) => {
      const charges = ops.map((x) => x.charge as INameValuePair<number>)
      if (formState.group) {
        formState.group.values = [...charges]
      }
      calcServiceCharges()
    }

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<AddonFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? props.editData?.group?.name || null : null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `appGlobals/${actions.GlobalAction.SET_DELETE_SERVICE_CHARGE}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess && !isEdit.value) {
        Object.assign(formState, getInitialState())
        selectedCharges.value = []
      }
    }

    const calcServiceCharges = () => {
      const groupTax = formState.group || null
      appliedCharges.value = []
      const groupNameValues = [] as INameValuePair<number>[]

      if (groupTax) {
        groupNameValues.push(...groupTax.values)
      }
      calcAddonCharge(
        groupTax?.applyType || AddonApplyType.Basic,
        groupNameValues,
        appliedCharges.value,
      )
    }

    const calcAddonCharge = (
      type: AddonApplyType,
      values: INameValuePair<number>[],
      appliedArr: INameValuePair<number>[],
    ) => {
      const subTotal = testValue.value
      let newSubTotal = subTotal || 0

      if (newSubTotal && newSubTotal > 0) {
        values?.forEach((x) => {
          const nameFormat = `${x.name} (${x.value}%)`
          const percentValue = utils.getValueOfPercentage(newSubTotal, x.value)
          // per += percentValue
          appliedArr?.push({ name: nameFormat, value: percentValue })
          if (type === AddonApplyType.InOrder) {
            newSubTotal += percentValue
          }
        })
      }
    }

    watch([testValue, () => formState.group?.applyType], () => {
      calcServiceCharges()
    })

    return {
      formState,
      formRef,
      rules,
      labelCol,
      wrapperCol,
      isLoading,
      handleFinish,
      serviceChargeOptions,
      handleValuesSelect,
      selectedCharges,
      testValue,
      appliedCharges,
      isEdit,
      // handleSubmit,
    }
  },
})
</script>

<style scoped>
.bg-zoho-light {
  background-color: #fbf9fa;
  /* background-color: #f7f7f7; Darker*/
}
p {
  margin-bottom: 1px;
}
</style>
